import React from 'react';
import { Header } from './styles';
import HapLogo from '../../assets/images/hapvida-logo.svg';
import GndiLogo from '../../assets/images/gndi-logo.svg';
import { Grid } from '@mui/material';
import UserMenu from '../UserMenu';

interface HeaderMinimalProps {
  user:
    | {
        profile: string;
        token: string;
        name: string;
        type: string;
      }
    | null
    | undefined;
  logOut?: () => void;
  loginRoute: string;
}

const HeaderMinimal: React.FC<HeaderMinimalProps> = ({
  user,
  logOut,
  loginRoute,
}) => {
  return (
    <Header>
      <Grid container item alignItems="center" gap={2}>
        <img src={HapLogo} alt="Logo Hapvida" />
        <img src={GndiLogo} alt="Logo GNDI" />
      </Grid>
      {user ? (
        <UserMenu username={user.name} role={user.profile} onLogOut={logOut} />
      ) : (
        <span>
          Já tem cadastro?
          <strong>
            <a href={loginRoute}>Efetue o login</a>
          </strong>
        </span>
      )}
    </Header>
  );
};

export default HeaderMinimal;
