import { COLORS } from '../../themes/colors';
import styled, { css } from 'styled-components';
import { Grid, styled as muiStyled } from '@mui/material';

import Typography from '../Typography';

interface CardContainerProps {
  checked: boolean;
}

export const CardContainer = styled(Grid)<CardContainerProps>`
  width: 100%;
  padding: 16px;
  border: 1px solid ${COLORS.MONOCHROMATIC.GRAY3};
  border-radius: 4px;
  background: ${COLORS.WHITE};

  i {
    color: ${COLORS.PRIMARY.ORANGE.MAIN};
  }

  :hover {
    cursor: pointer;
  }

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${COLORS.PRIMARY.ORANGE.MAIN};
      background: linear-gradient(
          0deg,
          rgba(245, 182, 127, 0.08),
          rgba(245, 182, 127, 0.08)
        ),
        #ffffff;
    `}
`;

export const TitleDescription = muiStyled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 'bold',
}));

export const BodyDescription = muiStyled(Typography)(() => ({
  fontSize: '12px',
}));
