import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import HapvidaLogo from '../../../assets/images/hapvida-logo.svg';
import HapvidaLogoLight from '../../../assets/images/hapvida-logo-light.svg';
import NdiLogo from '../../../assets/images/ndi-logo.svg';
import NdiLogoLight from '../../../assets/images/ndi-logo-light.svg';

import {
  Drawer,
  DrawerContainer,
  MainContent,
  PageContainer,
  RouteGroupName,
  RouteList,
} from './styles';
import Icon from '../../Icon';
import { COLORS } from '../../../themes/colors';
import PageHeader from '../../PageHeader';
import RouteLink from './components/RouteLink';

export interface ICoreGroupRoute<Route = ICoreRoute> {
  id: number;
  name?: string;
  collapsedName?: string;
  routes: Array<Route>;
}

export interface ICoreRoute {
  Page: React.FC<any>;
  path: string;
  name: string;
  collapsedName?: string;
  Icon: JSX.Element;
  userTypes: UserType[];
  disabled?: boolean;
  disabledOnRoute?: string;
}

export enum UserType {
  Backoffice = 'Backoffice',
  Agency = 'Agency',
  Company = 'Company',
}

interface DrawerLayoutProps {
  route?: {
    name?: string;
    icon?: JSX.Element;
    navigateBackPath?: string;
  };
  subRoute?: string;
  groupRoutes: Array<ICoreGroupRoute>;
  user?: {
    profile: string;
    token: string;
    name: string;
    type: UserType;
  } | null;
  selectComponent?: JSX.Element;
  subHeader?: JSX.Element;
  drawerOpen?: boolean;
  disabledRoutes?: string[];
  setDrawerOpen(state: boolean): void;
  onLogOut?(): void;
  calculateBarSize?(ref: any): void;
  totalQuotationsAndCompany?: number;
}

const DrawerLayout: React.FC<DrawerLayoutProps> = ({
  route,
  subRoute,
  user,
  children,
  groupRoutes,
  subHeader,
  drawerOpen,
  disabledRoutes,
  setDrawerOpen,
  onLogOut,
  calculateBarSize,
  selectComponent,
  totalQuotationsAndCompany,
}) => {
  const [headerOffset, setHeaderOffset] = useState('90px');
  const appBarRef = useRef(null);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) setDrawerOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    const listener = () => {
      if (appBarRef.current) {
        const height = getComputedStyle(appBarRef.current).height;
        setHeaderOffset(height);

        if (calculateBarSize) {
          calculateBarSize(appBarRef);
        }
      }
    };

    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [calculateBarSize, subHeader]);

  const filteredGroupRoutes = useMemo(
    () =>
      groupRoutes
        .map(({ routes, ...rest }) => ({
          ...rest,
          routes: routes.filter(
            ({ userTypes }) => user?.type && userTypes.includes(user.type),
          ),
        }))
        .filter(({ routes }) => routes.length > 0),
    [groupRoutes, user?.type],
  );

  const handleRouteDisabled = (route?: ICoreRoute): boolean => {
    if (disabledRoutes?.find(el => el === route?.path)) {
      return true;
    }

    if (route?.disabled) {
      return true;
    }

    if (route?.disabledOnRoute) {
      const currentRoute = window.location.pathname;

      return currentRoute.includes(route.disabledOnRoute);
    }

    return false;
  };
  return (
    <PageContainer>
      <PageHeader
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        innerRef={appBarRef}
        user={user}
        route={route}
        subHeader={subHeader}
        totalQuotationsAndCompany={totalQuotationsAndCompany}
        onLogOut={onLogOut}
        subRoute={subRoute}
      />
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={drawerOpen}
      >
        <DrawerContainer
          open={!!drawerOpen}
          alignItems={drawerOpen ? 'start' : 'center'}
          gap={2}
          backofficeVision={user?.type === UserType.Backoffice}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent={drawerOpen ? 'start' : 'center'}
          >
            {drawerOpen && (
              <Stack direction="row" gap={2} order={isMobile ? -1 : 0}>
                <img
                  src={
                    user?.type === UserType.Backoffice
                      ? HapvidaLogoLight
                      : HapvidaLogo
                  }
                  alt="Hapvida"
                  width={104}
                />
                <img
                  src={
                    user?.type === UserType.Backoffice ? NdiLogoLight : NdiLogo
                  }
                  alt="Ndi"
                  width={104}
                />
              </Stack>
            )}
            <Icon
              name={
                isMobile
                  ? 'close'
                  : drawerOpen
                  ? 'side-bar-close'
                  : 'side-bar-open'
              }
              size={32}
              color={
                user?.type === UserType.Backoffice
                  ? COLORS.WHITE
                  : COLORS.PRIMARY.ORANGE.MAIN
              }
              onClick={toggleDrawer}
            />
          </Stack>

          {drawerOpen && user?.type === UserType.Backoffice && (
            <Stack direction="row" gap={1} sx={{ mt: 5, mb: 4 }}>
              <Icon name="hapvida-logo" size={24} color={COLORS.WHITE} />
              <Typography color={COLORS.WHITE}>
                Você está na visão colaborador
              </Typography>
            </Stack>
          )}

          {selectComponent}

          <RouteList gap={3}>
            {filteredGroupRoutes.map(group => (
              <Stack
                gap={2}
                key={group.id}
                alignItems={drawerOpen ? 'normal' : 'center'}
              >
                {group.name && (
                  <RouteGroupName open={drawerOpen} variant="smallText">
                    {drawerOpen ? group.name : group.collapsedName}
                  </RouteGroupName>
                )}
                {group.routes.map(route => (
                  <RouteLink
                    key={route.path}
                    route={route}
                    drawerOpen={!!drawerOpen}
                    backofficeVision={user?.type === UserType.Backoffice}
                    disabled={handleRouteDisabled(route)}
                  >
                    {route.Icon}

                    {drawerOpen ? (
                      <Typography>{route.name}</Typography>
                    ) : (
                      <Typography variant="smallText">
                        {route.collapsedName}
                      </Typography>
                    )}
                  </RouteLink>
                ))}
              </Stack>
            ))}
          </RouteList>
        </DrawerContainer>
      </Drawer>
      <MainContent component="main" headerOffset={headerOffset}>
        <Container maxWidth="xl">{children}</Container>
      </MainContent>
    </PageContainer>
  );
};

export default DrawerLayout;
