import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import Icon from '../Icon';
import { styled as muiStyled } from '@mui/material/styles';

import styled from 'styled-components';

import { Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '../../themes/colors';

interface TitleProps {
  type: 'default' | 'alert' | 'success' | 'gray';
}

interface StyledDialogContentProps extends DialogContentProps {
  hasIcon?: boolean;
}

export const ModalWrapper = muiStyled(Dialog)({
  '& .MuiDialog-container': {},

  '& .MuiDialog-paper': {
    padding: '1rem',
    borderRadius: '8px',
  },

  '& .MuiDialogTitle-root': {
    padding: '1rem 1rem 1rem 0.6rem',

    display: 'flex',
    gap: '1rem',
  },
});

export const Title = muiStyled(Typography)<TitleProps>(({ type }) => ({
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '20px',

  marginBottom: '0.75rem',

  color:
    type === 'default'
      ? COLORS.PRIMARY.BLUE['+1']
      : type === 'alert'
      ? COLORS.PRIMARY.RED['+1']
      : type === 'gray'
      ? COLORS.MONOCHROMATIC.GRAY4
      : COLORS.PRIMARY.GREEN['+1'],
}));

export const StyledIcon = styled(Icon)<TitleProps>(({ type }) => ({
  color:
    type === 'default'
      ? COLORS.PRIMARY.BLUE['+1']
      : type === 'alert'
      ? COLORS.PRIMARY.RED['+1']
      : type === 'gray'
      ? COLORS.MONOCHROMATIC.GRAY4
      : COLORS.PRIMARY.GREEN['+1'],
}));

export const Subtitle = muiStyled(Typography)({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '20px',
  color: COLORS.MONOCHROMATIC.GRAY4,
});

export const StyledDialogContent = muiStyled(
  DialogContent,
)<StyledDialogContentProps>(({ hasIcon }) => ({
  padding: hasIcon ? '1.5rem 0.5rem 0.5rem 1.5rem' : '0.5rem',
  marginLeft: hasIcon ? '1.5rem' : '0',
}));

export const Content = muiStyled(Box)({
  margin: '0.7rem 0 1.5rem 0',
});
