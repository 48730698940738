import { ReactNode, useState } from 'react';
import { useCallback, useMemo, useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
  ActionArea,
  ActionButton,
  ActionIcon,
  ArchiveArea,
  ArchiveDescription,
  ArchiveIcon,
  ArchiveTitle,
  ButtonDownload,
  Container,
  Description,
  DividerArea,
  DragFileText,
  DropArea,
  ErrorArea,
  ErrorIcon,
  ErrorMessage,
  Heading,
  IconButton,
  InfoItem,
  InfoStack,
  InnerContainer,
  ProcessedFileErrorMessageArea,
  RightInfo,
  SelectFileText,
  SubmittingContent,
  SubmittingText,
  Title,
} from './styles';
import Icon from '../Icon';
import { formatBytes } from '../../utils/formatBytes';
import { Base64File } from '../../utils/base64File';

import * as mime from 'mime-types';
import IDragAndDropFile from './dtos/IDragDropFile';
import formatDateISO from '../../utils/formatDateISO';
import { truncateString } from '../../utils/truncateString';
import PulsarAnimationLoading from '../PulsarAnimation';

const ERROR_MESSAGES = {
  'file-too-large': 'Seu arquivo ultrapassa o tamanho máximo.',
  'file-invalid-type':
    'Parece que você está tentando subir um arquivo com formato diferente do permitido.',
};

interface MultipleFiles {
  files: IDragAndDropFile[];
  fileChanged?: IDragAndDropFile;
}

export interface DragAndDropProps<
  Multiple extends boolean | undefined = undefined,
> {
  value: Multiple extends true
    ? IDragAndDropFile[]
    : IDragAndDropFile | undefined;
  onChange: (
    file: Multiple extends true ? MultipleFiles : IDragAndDropFile | undefined,
    error?: string,
  ) => void | Promise<void>;
  title?: string;
  multiple?: Multiple;
  maxSize?: number;
  acceptedTypes?: string | string[];
  model?: string;
  errorMessage?: ReactNode;
  isAvatar?: boolean;
  children?: ReactNode;
  onDelete?(file: IDragAndDropFile): Promise<void>;
  hideEditButton?: boolean;
  onDownloadFileItem?(file: IDragAndDropFile): Promise<void | String>;
  showHeader?: boolean;
  isSubmittingFiles?: boolean;
  isValidatingFiles?: boolean;
  itHasError?: boolean;
  processedFileError?: ReactNode | undefined;
  rightInfo?: string;
  containerStyles?: Object;
  description?: ReactNode;
  hideFileSize?: boolean;
  isTitle?: boolean;
}

const DragAndDrop = <Multiple extends boolean = false>({
  title,
  value,
  onChange,
  multiple,
  maxSize = 8e6, // 8MB
  acceptedTypes: acceptedTypesAny,
  model,
  errorMessage,
  isAvatar,
  children,
  onDelete,
  onDownloadFileItem,
  hideEditButton,
  showHeader = false,
  isSubmittingFiles = false,
  isValidatingFiles = false,
  itHasError = false,
  processedFileError,
  rightInfo,
  containerStyles,
  description,
  hideFileSize = false,
  isTitle = true,
}: DragAndDropProps<Multiple>) => {
  const [replacing, setReplacing] = useState<number>();
  const [internalError, setInternalError] = useState<string>();

  const error = useMemo(
    () => errorMessage ?? internalError,
    [errorMessage, internalError],
  );

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const { mimeTypes, fileTypes } = useMemo(() => {
    if (!acceptedTypesAny)
      return { mimeTypes: undefined, fileTypes: undefined };

    const array = Array.isArray(acceptedTypesAny)
      ? acceptedTypesAny
      : [acceptedTypesAny];

    const mimeTypes = array.map(type => mime.lookup(type) || type);
    const fileTypes = mimeTypes.map(type => mime.extension(type) as string);

    return { mimeTypes, fileTypes };
  }, [acceptedTypesAny]);

  useEffect(() => {
    if (typeof multiple === 'number' && multiple < 2) {
      throw new Error(
        "Prop 'multiple' must be false, true, or a number greater than 1.",
      );
    }
  }, [multiple]);

  const hasFile = Array.isArray(value) ? value.length > 0 : !!value?.fileName;
  const files = useMemo<IDragAndDropFile[]>(() => {
    if (Array.isArray(value)) return value;
    if (value?.fileName !== undefined) return [value];
    return [];
  }, [value]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const error = rejectedFiles
        .flatMap(file => file.errors.map(error => error.code))
        .find(value => Object.keys(ERROR_MESSAGES).includes(value)) as
        | keyof typeof ERROR_MESSAGES
        | undefined;

      setInternalError(ERROR_MESSAGES[error!] ?? error);

      const files = await Promise.all(
        acceptedFiles.map(file => Base64File.fromFile(file)),
      );

      if (!multiple) {
        (onChange as (file: IDragAndDropFile, error?: string) => void)(
          files[0],
          error,
        );
      } else {
        const newValue = [...(value as IDragAndDropFile[])];

        if (replacing !== undefined) {
          newValue.splice(replacing, 1, ...files);
          setReplacing(undefined);
        } else {
          newValue.push(...files);
        }

        (onChange as (files: MultipleFiles, error?: string) => void)(
          {
            files: newValue,
            fileChanged: files[0],
          },
          error,
        );
      }
    },
    [multiple, onChange, replacing, value],
  );

  const handleDeleteFile = useCallback(
    async (file: IDragAndDropFile) => {
      if (onDelete) {
        await onDelete(file);
      }
    },
    [onDelete],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: multiple !== false,
    maxFiles: typeof multiple === 'number' ? multiple : undefined,
    accept: mimeTypes,
    maxSize,
  });

  const DropAreaComponent = (
    <ArchiveArea>
      <DropArea {...getRootProps({ refKey: 'innerRef' })} errorFile={!!error}>
        <Stack direction="column" alignItems="center">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Icon name="file-document" size={24} />
            <DragFileText variant="text">
              <SelectFileText variant="text">
                Selecione um arquivo
              </SelectFileText>{' '}
              ou arraste para essa área
            </DragFileText>
          </Stack>
        </Stack>
      </DropArea>

      {!!error && (
        <ErrorArea
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          alignSelf="flex-start"
          spacing={0.5}
        >
          <ErrorIcon name="alert" size={18} />
          <ErrorMessage>
            <strong>Atenção!</strong> {error}
          </ErrorMessage>
        </ErrorArea>
      )}
    </ArchiveArea>
  );

  return (
    <Container
      ithaserror={itHasError ? 'true' : 'false'}
      containerstyles={containerStyles}
    >
      <InnerContainer
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {(isSubmittingFiles || isValidatingFiles) && (
          <SubmittingContent>
            <PulsarAnimationLoading width="32px" height="32px" />
            <SubmittingText>
              {isValidatingFiles
                ? 'Estamos finalizando a leitura, em instantes tudo estará pronto para você.'
                : 'Subindo arquivo...'}
            </SubmittingText>
          </SubmittingContent>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          {title && <Heading>{title}</Heading>}
          <RightInfo>{rightInfo}</RightInfo>
        </Stack>
        {children && (
          <div style={{ marginTop: '8px', width: '100%' }}>{children}</div>
        )}
        <input {...getInputProps()} />
        {showHeader && files.length === 0 && (
          <>
            {description && <Typography mb={'16px'}>{description}</Typography>}

            <InfoStack marginTop={0} direction="row">
              {fileTypes?.length && (
                <InfoItem
                  sx={{
                    width: { xs: 'calc(50% - 8px)', sm: 'unset' },
                    justifyContent: { xs: 'flex-end', sm: 'flex-start' },
                    pr: '8px',
                  }}
                >
                  <Title variant="text">
                    {fileTypes.length > 1 ? 'Formatos aceitos' : 'Formato'}
                  </Title>
                  <Description>
                    {fileTypes.join(', ').toUpperCase()}
                  </Description>
                </InfoItem>
              )}
              {!hideFileSize && (
                <InfoItem
                  sx={{
                    width: { xs: 'calc(50% - 8px)', sm: 'unset' },
                    pl: '8px',
                  }}
                >
                  <Title variant="text">Tamanho</Title>
                  <Description>{formatBytes(maxSize)}</Description>
                </InfoItem>
              )}

              {model && (
                <InfoItem sx={{ order: { xs: 1, sm: 0 } }}>
                  {!isMobile && isTitle && (
                    <Title variant="text">Arquivo modelo</Title>
                  )}
                  <ButtonDownload
                    onClick={() => window.open(model, '_blank')}
                    rightComponent={<IconButton name="file-check" size={16} />}
                  >
                    Baixar modelo
                  </ButtonDownload>
                </InfoItem>
              )}
            </InfoStack>
          </>
        )}
        {(multiple || !hasFile) && DropAreaComponent}
        {isAvatar && hasFile && (
          <Stack direction="row" justifyContent="center" spacing={8}>
            {fileTypes?.length && (
              <Stack direction="column" spacing={2}>
                {description}
                <Title variant="text">
                  {fileTypes.length > 1 ? 'Formatos aceitos' : 'Formato'}
                </Title>
                <Description>{fileTypes.join(', ').toUpperCase()}</Description>
              </Stack>
            )}
            <Stack direction="column" spacing={2}>
              <Title variant="text">Tamanho</Title>
              <Description>{formatBytes(maxSize)}</Description>
            </Stack>
          </Stack>
        )}
        {isAvatar && hasFile && DropAreaComponent}
        {(!isSubmittingFiles || !isValidatingFiles) && (
          <>
            {files.length > 0 && !isAvatar && (
              <ArchiveArea gap={3}>
                {(multiple || !hasFile) && <DividerArea />}
                {files.map((file, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    key={index}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <ArchiveIcon size={24} name="file-check" />
                      <Stack>
                        <ArchiveTitle variant="text">
                          {truncateString(file.fileName, 40)}
                        </ArchiveTitle>
                        <ArchiveDescription>
                          {file.updatedAt || file.date ? (
                            <>
                              Realizado
                              <b>
                                {` ${formatDateISO(
                                  file.updatedAt || file.date,
                                  "dd 'de' MMMM 'de' yyyy",
                                )} • (${file.size})`}
                              </b>
                            </>
                          ) : (
                            file.createdAt && (
                              <>
                                Realizado
                                <b>
                                  {` ${formatDateISO(
                                    file.createdAt,
                                    "dd 'de' MMMM 'de' yyyy",
                                  )} • (${file.size})`}
                                </b>
                              </>
                            )
                          )}
                        </ArchiveDescription>
                      </Stack>
                    </Stack>
                    <ActionArea
                      direction="row"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      {onDownloadFileItem && (
                        <ActionButton onClick={() => onDownloadFileItem(file)}>
                          <ActionIcon size={24} name="download" />
                        </ActionButton>
                      )}
                      {!hideEditButton && (
                        <ActionButton
                          onClick={() => {
                            setReplacing(index);
                            open();
                          }}
                        >
                          <ActionIcon size={24} name="edit" />
                        </ActionButton>
                      )}
                      {!!onDelete && (
                        <ActionButton onClick={() => handleDeleteFile(file)}>
                          <ActionIcon size={24} name="delete" />
                        </ActionButton>
                      )}
                    </ActionArea>
                  </Stack>
                ))}
              </ArchiveArea>
            )}

            {itHasError && (
              <ErrorArea
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignSelf="flex-start"
                spacing={1.5}
              >
                <ErrorIcon name="alert" size={18} />
                <ProcessedFileErrorMessageArea>
                  {processedFileError}
                </ProcessedFileErrorMessageArea>
              </ErrorArea>
            )}
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

export default DragAndDrop;
