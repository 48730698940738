import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

import Icon from '../Icon';

import { COLORS } from '../../themes/colors';

interface ArrowProps {
  length: number;
  position: number;
}

export const CardsContainer = muiStyled(Box)(({ theme: { breakpoints } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

export const Card = muiStyled(Box)(() => ({
  flex: 1,
  padding: '16px',
  background: '#fff',
  borderRadius: '8px',
}));

export const CurrentStep = muiStyled(Box)(() => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  fontWeight: 700,
  fontSize: '0.875rem',
  color: COLORS.WHITE,

  background: COLORS.PRIMARY.ORANGE.MAIN,
}));

export const Header = muiStyled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  marginBottom: '8px',
}));

export const Content = muiStyled(Box)(() => ({
  color: '#000',
  fontWeight: 400,
  fontSize: '0.75rem',
}));

export const Arrow = muiStyled(Icon)<ArrowProps>(({ position, length }) => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
  display: length === position + 1 ? 'none' : '',
}));

export const ArrowWrapper = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
