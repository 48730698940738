import { styled as muiStyled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import { COLORS } from '../../../../../themes/colors';

interface StyledActionCellProps {
  collapsed: string;
  ithaserror: string;
  ithaserrormessage: string;
}

export const StyledActionCell = muiStyled(TableCell)<StyledActionCellProps>(
  ({ collapsed, ithaserror, ithaserrormessage }) => ({
    background:
      collapsed === 'true' && ithaserror === 'false'
        ? 'linear-gradient(0deg, rgba(244, 158, 0, 0.12), rgba(244, 158, 0, 0.12)), #FFFFFF'
        : ithaserror === 'true'
        ? 'linear-gradient(0deg, rgba(201, 54, 0, 0.04), rgba(201, 54, 0, 0.04)), #FFFFFF'
        : COLORS.MONOCHROMATIC.GRAY1,

    position: 'sticky',
    right: 0,
    padding: 0,

    borderBottom:
      ithaserror === 'true' && ithaserrormessage === 'false'
        ? `1px solid #FDF7F5`
        : ithaserror === 'true' && ithaserrormessage === 'true'
        ? `2px solid #FDF7F5`
        : `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,

    '&:hover': {
      background:
        ithaserror === 'false' &&
        'linear-gradient(0deg, rgba(244, 158, 0, 0.12), rgba(244, 158, 0, 0.12)), #FFFFFF',

      borderBottom:
        ithaserror === 'false' && `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
    },
  }),
);

export const ActionBox = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    paddingRight: '20px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
  },
}));

export const CollapsedButton = muiStyled(Button)(() => ({
  '&.MuiButton-root': {
    width: '32px',
    height: '32px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const EditButton = muiStyled(IconButton)(() => ({
  '&.MuiIconButton-root': {
    border: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,
    width: '32px',
    height: '32px',
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
      color: COLORS.PRIMARY.ORANGE.MAIN,
      cursor: 'pointer',
      i: {
        color: COLORS.PRIMARY.ORANGE.MAIN,
      },
    },
  },
}));
