import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { animated, useSpring } from 'react-spring';
import PageHeader from '../../PageHeader';

import HapvidaNdiLogo from '../../../assets/images/new-hapvida-ndi-rgb.svg';
import HapvidaNdiLogoWhite from '../../../assets/images/new-hapvida-ndi-white.svg';

import Steps from './components/Steps';
import {
  PageContainer,
  Drawer,
  MainContent,
  LogoContainer,
  FirstStepContent,
} from './styles';
import Typography from '../../Typography';
import { UserType } from '../DrawerLayout';
import Icon from '../../Icon';
import { COLORS } from '../../../themes/colors';

interface StepLayoutProps {
  user?: {
    profile: string;
    token: string;
    name: string;
    type: UserType;
  } | null;
  steps: Array<{
    label: string;
    createdAt?: string;
    completed: boolean;
    blocked?: boolean;
  }>;
  route?: {
    name?: string;
    icon?: JSX.Element;
  };
  subRoute?: string | null;
  firstStepTitle?: {
    name?: string;
    icon?: JSX.Element;
  };
  currentStep: number;
  sideBarTitle?: string;
  sideBarSubtitle?: React.ReactNode;
  startImage?: string;
  hasAnimation?: boolean;
  onLogOut?(): void;
  onStepChange(step: number): void;
  hasHeader?: boolean;
  hasFirstStep?: boolean;
  enableOnClickLogo?: boolean;
  customRedirectRoute?: string;
  firstStepColor?: string;
}

const StepLayout: React.FC<StepLayoutProps> = ({
  user,
  children,
  steps,
  currentStep,
  subRoute,
  route,
  firstStepTitle,
  sideBarTitle,
  sideBarSubtitle,
  startImage,
  hasAnimation,
  onLogOut,
  onStepChange,
  hasHeader = true,
  hasFirstStep = true,
  enableOnClickLogo = false,
  firstStepColor,
  customRedirectRoute,
}) => {
  const appBarRef = useRef(null);
  const [headerOffset, setHeaderOffset] = useState('90px');
  const isFirstStep = currentStep === 0 && hasFirstStep;
  const [drawerOpen, setDrawerOpen] = useState(!!hasAnimation);
  const [drawerModal, setDrawerModal] = useState(true);
  const contentPageAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: !!hasAnimation ? 2400 : 0,
    config: {
      duration: 800,
    },
  });

  useEffect(() => {
    const listener = () => {
      if (appBarRef.current) {
        const height = getComputedStyle(appBarRef.current).height;
        setHeaderOffset(height);
      }
    };

    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDrawerOpen(false);
    }, 2000);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const handleClick = () =>
    navigate(customRedirectRoute ? customRedirectRoute : '/cotacoes');

  return (
    <PageContainer>
      {hasHeader && (
        <PageHeader
          innerRef={appBarRef}
          user={user}
          onLogOut={onLogOut}
          route={isFirstStep ? firstStepTitle : route}
          subRoute={isFirstStep ? '' : subRoute}
          drawerOpen={drawerModal}
          toggleDrawer={() => setDrawerModal(!drawerModal)}
          drawerCloseWidth={500}
          drawerOpenWidth={500}
        />
      )}
      <Drawer
        isFirstStep={isFirstStep}
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        showImage={drawerOpen}
        open={drawerModal}
        transitionDuration={400}
        startImage={startImage}
        firstStepColor={firstStepColor}
      >
        <Stack
          alignItems="center"
          sx={
            isMobile
              ? { padding: '16px 16px' }
              : { padding: '16px 80px', maxWidth: 500 }
          }
          gap={6}
        >
          <LogoContainer
            direction="row"
            justifyContent="space-between"
            pt="2px"
          >
            <img
              src={isFirstStep ? HapvidaNdiLogoWhite : HapvidaNdiLogo}
              alt="Hapvida"
              onClick={enableOnClickLogo ? handleClick : undefined}
            />

            {isMobile && (
              <Icon
                name="close"
                size={32}
                color={COLORS.BLACK}
                onClick={() => setDrawerModal(!drawerModal)}
              />
            )}
          </LogoContainer>
        </Stack>
        {isFirstStep ? (
          <FirstStepContent gap={2}>
            <Typography variant="h4">{sideBarTitle}</Typography>
            <Typography variant="text">{sideBarSubtitle}</Typography>
          </FirstStepContent>
        ) : (
          <Stack
            sx={
              isMobile
                ? { padding: '16px 16px' }
                : { padding: '16px 80px', maxWidth: 500 }
            }
          >
            <Steps
              activeStep={currentStep}
              steps={steps}
              onStepChange={onStepChange}
            />
          </Stack>
        )}
      </Drawer>
      <MainContent headerOffset={hasHeader ? headerOffset : '0px'}>
        <Container maxWidth="lg">
          <animated.div style={contentPageAnimation}>{children}</animated.div>
        </Container>
      </MainContent>
    </PageContainer>
  );
};

export default StepLayout;
