import Stack from '@mui/material/Stack';

import GNDI from './assets/gndi.svg';
import Hapvida from './assets/hapvida.svg';

import { Image, CompanyLabel } from './styles';

interface CompanyProps {
  company: string;
}

export enum CompanyEnum {
  GNDI = 'GNDI',
  Gndi = 'Gndi',
  NDI = 'NDI',
  Hapvida = 'Hapvida',
}

export const CompanyValues: {
  [x: string]: {
    image: string;
    name: string;
  };
} = {
  [CompanyEnum.GNDI]: {
    image: GNDI,
    name: 'GNDI',
  },
  [CompanyEnum.Hapvida]: {
    image: Hapvida,
    name: 'Hapvida',
  },
  [CompanyEnum.Gndi]: {
    image: GNDI,
    name: 'Gndi',
  },
  [CompanyEnum.NDI]: {
    image: GNDI,
    name: 'NDI',
  },
};

const Company = ({ company }: CompanyProps) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Image
        sx={{
          transform: company === CompanyEnum.GNDI ? 'scale(1.4)' : 'unset',
        }}
        src={CompanyValues[company]?.image}
        alt={CompanyValues[company]?.name}
      />

      <CompanyLabel>{CompanyValues[company]?.name}</CompanyLabel>
    </Stack>
  );
};

export default Company;
