import Icon from '../Icon';
import { COLORS } from '../../themes/colors';
import { Avatar, Stack, Typography } from '@mui/material';
import { Field, StyledTypography, Value } from './styles';
import { Filename } from './styles';
import { IconName } from '../Icon/types';

interface DataTextProps<T = string | number> {
  field: string;
  value?: number | string | Array<T> | undefined;
  sufix?: string;
  file?: () => void;
  arrayMappedValues?: {
    [x: number | string]: {
      name: string;
      icon: IconName;
    };
  };
  label?: string;
  imageSource?: string;
  isUser?: boolean;
  capitalize?: boolean;
  emptyState?: string;
}

const DataText = ({
  field,
  value,
  sufix,
  file,
  arrayMappedValues,
  label,
  imageSource,
  isUser,
  capitalize,
  emptyState = '-',
}: DataTextProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Field>{field}</Field>
        {label ? (
          <StyledTypography fontSize={12}>{label}</StyledTypography>
        ) : null}
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        {Array.isArray(value) ? (
          value.map(val => (
            <Stack
              key={val}
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              {arrayMappedValues && (
                <>
                  <Typography fontSize={14}>
                    {arrayMappedValues[val].name}
                  </Typography>
                  <Icon
                    name={arrayMappedValues[val].icon}
                    size={24}
                    color={COLORS.PRIMARY.ORANGE.MAIN}
                  />
                </>
              )}
            </Stack>
          ))
        ) : file ? (
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            sx={{ cursor: value ? 'pointer' : 'default' }}
            onClick={value ? file : () => {}}
          >
            {value ? (
              <>
                <Icon
                  name="file-check"
                  size={16}
                  color={COLORS.PRIMARY.BLUE['-1']}
                />
                <Filename>{value}</Filename>
              </>
            ) : (
              '-'
            )}
          </Stack>
        ) : isUser ? (
          <Stack direction="row" gap={1} alignItems="center">
            <Avatar src={imageSource} sx={{ width: 24, height: 24 }}>
              <Icon name="user" color={COLORS.PRIMARY.BLUE['-1']} />
            </Avatar>
            <Value capitalize={capitalize}>{value}</Value>
          </Stack>
        ) : (
          <Value capitalize={capitalize}>
            {value ?? emptyState} {sufix}
          </Value>
        )}
      </Stack>
    </Stack>
  );
};

export default DataText;
