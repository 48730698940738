import { Stack, Typography, Box } from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { COLORS } from '../../themes/colors';
import Checkbox from '../Checkbox';
import Icon from '../Icon';
import PulsarAnimationLoading from '../PulsarAnimation';
import {
  Wrapper,
  Header,
  FilterButton,
  StyledCollapse,
  FieldWrapper,
  SelectAll,
} from './styles';

interface CollapsableSelectOption {
  key: string;
  label: string;
  content?: ReactNode;
}

interface CollapsableSelectProps {
  title: string;
  items: CollapsableSelectOption[];
  selected: string[];
  onSelect: (selected: string[]) => void;
  isMultiple?: boolean;
  disabled?: boolean;
  loading?: boolean;
  loadNextPage?: () => void;
  hasMore?: boolean;
}

const CollapsableSelect = ({
  title,
  items,
  selected,
  onSelect,
  isMultiple,
  disabled,
  loading,
  loadNextPage,
  hasMore,
}: CollapsableSelectProps) => {
  const [openState, setOpenState] = useState<boolean>(false);
  const toggleOpenState = () => setOpenState(prev => !prev);

  const selectedItems = useMemo(
    () => items.filter(({ key }) => selected.includes(key)),
    [items, selected],
  );

  const allSelected = useMemo(
    () => items.length === selected.length,
    [items, selected],
  );

  const toggleSelect = (key: string) => {
    if (!isMultiple) {
      if (selected.includes(key)) {
        onSelect([]);
      } else {
        onSelect([key]);
      }
      return;
    }

    if (selected.includes(key)) {
      onSelect(selected.filter(item => item !== key));
    } else {
      onSelect([...selected, key]);
    }
  };

  const toggleSelectAll = () => {
    if (selected.length === items.length) {
      onSelect([]);
    } else {
      onSelect(items.map(({ key }) => key));
    }
  };

  const buildSelectedButtons = () => (
    <Box flexWrap="wrap" display="flex">
      {selectedItems.map(({ key, label }) => (
        <FilterButton mt={1} key={key} onClick={e => e.stopPropagation()}>
          <Typography>{label}</Typography>
          <Icon
            onClick={e => {
              e.stopPropagation();
              toggleSelect(key);
            }}
            name="close"
            size={16}
            color={COLORS.WHITE}
          />
        </FilterButton>
      ))}
    </Box>
  );

  const renderFiltersList = () =>
    items.map(({ key, content, label }) => (
      <FieldWrapper key={key} onClick={() => toggleSelect(key)}>
        <Checkbox label={label} checked={selected.includes(key)} />
        <Typography component="div">{content ?? label}</Typography>
      </FieldWrapper>
    ));

  const renderContent = () => {
    if (loading && items.length === 0) {
      return (
        <Box display="flex" justifyContent="center" mt={2} mb={2}>
          <PulsarAnimationLoading
            width="30px"
            height="30px"
            color={COLORS.PRIMARY.ORANGE.MAIN}
          />
        </Box>
      );
    }

    return (
      <>
        {isMultiple && (
          <SelectAll>
            <Checkbox
              label="Selecionar todos"
              checked={allSelected}
              onChange={toggleSelectAll}
            />
            <Typography>Selecionar todos</Typography>
          </SelectAll>
        )}
        <Stack pl="4px" pt="8px" pb="8px" maxHeight="265px" overflow="auto">
          {loadNextPage ? (
            <InfiniteScroll
              height={265}
              dataLength={items.length}
              next={loadNextPage}
              hasMore={hasMore ?? false}
              loader={<></>}
            >
              {renderFiltersList()}
            </InfiniteScroll>
          ) : (
            renderFiltersList()
          )}
        </Stack>
      </>
    );
  };

  return (
    <Wrapper
      disabled={disabled}
      bgcolor={openState ? COLORS.WHITE : COLORS.MONOCHROMATIC.GRAY1}
    >
      <Header onClick={toggleOpenState}>
        <Stack>
          <Typography variant="text" mb={0.5}>
            {title}
          </Typography>
          {selected.length > 0 && !allSelected ? (
            buildSelectedButtons()
          ) : (
            <>
              <Typography variant="smallText">
                {selected.length === 0
                  ? 'Nenhum filtro disponível'
                  : allSelected
                  ? 'Todos selecionados'
                  : 'Nenhum selecionado'}
              </Typography>
            </>
          )}
        </Stack>

        <Icon name={openState ? 'chevron-up' : 'chevron-down'} size={24} />
      </Header>

      <StyledCollapse in={openState}>{renderContent()}</StyledCollapse>
    </Wrapper>
  );
};

export default CollapsableSelect;
