import { Stack, StackProps, styled as muiStyled } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import { COLORS } from '../../../../../themes/colors';

interface RouteItemInterface {
  selected: boolean;
  open: boolean;
  backofficeVision: boolean | undefined;
  disabled: boolean;
}

interface LinkStyledProps extends LinkProps {
  disabled?: boolean;
}

export const LinkStyled = muiStyled(Link)<LinkStyledProps>(({ disabled }) => ({
  ...(disabled && {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  }),
  ':focus': {
    background: 'rgba(237, 171, 56, 0.08)',
    cursor: 'pointer',
  },
}));

export const RouteItem = muiStyled(
  ({ ...props }) => <Stack component="li" {...props} />,
  {
    shouldForwardProp: prop => prop !== 'backofficeVision',
  },
)<StackProps & RouteItemInterface>(
  ({ open, selected, backofficeVision, disabled }) => {
    return {
      padding: 16,
      borderRadius: '8px',

      i: {
        fontSize: 24,
        color: COLORS.MONOCHROMATIC.GRAY4,
      },

      ':hover': {
        cursor: disabled ? 'not-allowed' : 'pointer',
        background: disabled ? 'transparent' : 'rgba(237, 171, 56, 0.08)',
      },

      ...(disabled && {
        opacity: 0.5,
        background: 'transparent',
      }),

      ...(selected && {
        background: 'rgba(237, 171, 56, 0.08)',
        '.MuiTypography-root': {
          fontWeight: 600,
        },
        i: {
          fontSize: 24,
          color: COLORS.PRIMARY.ORANGE.MAIN,
        },
      }),

      ...(!open && {
        height: 32,
        width: 32,
      }),

      ...(backofficeVision && {
        '.MuiTypography-root': {
          color: COLORS.WHITE,
        },
        i: {
          fontSize: 24,
          // color: COLORS.PRIMARY.ORANGE.MAIN,
          color: disabled
            ? COLORS.MONOCHROMATIC.GRAY2
            : COLORS.PRIMARY.ORANGE.MAIN,
        },
      }),

      ...(backofficeVision &&
        selected && {
          background: 'rgba(255, 255, 255, 0.08)',
        }),
    };
  },
);
